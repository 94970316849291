<template>
  <div class="footer">
    <div class="footer-main">
      <div class="footer-main-l">
        <div class="logo">
          <img src="./img/logo.webp" class="img100"> 
        </div>
        <div class="footer-f1">
          <div class="footer-f1-l">
            <div class="d6">
              <img src="./img/portal.webp">
              <span class="cur" @click="open(6)">企业门户</span>
            </div>
            <div class="d1">
              <img src="./img/icon2.webp">
              <span class="cur" @click="open(1)">招投标</span>
            </div>
            <div class="d3">
              <img src="./img/icon3.webp">
              <span class="cur" @click="toPaths('/connect',{type:1})">反舞弊</span>
            </div>
            <div class="d2">
              <img src="./img/1.jpg">
              <span>骆驼股份公众号</span>
            </div>
          </div>
          <div class="footer-f1-r">
            <div class="d7">
              <img src="./img/mail.webp">
              <span class="cur" @click="open(7)">企业邮箱</span>
            </div>
            <div class="d5">
              <img src="./img/icon1.webp">
              <span class="cur" @click="open(2)">采购系统</span>
            </div>
            <div class="d4">
              <img src="./img/icon4.webp">
              <span class="cur" @click="toPaths('/connect',{type:2})">信息安全</span>
            </div>
            <div class="d2">
              <img src="./img/2.jpg">
              <span>骆驼养车公众号</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-main-r">
        <div>
          <div class="title">产品方案</div>
          <div class="con mb20" @click="toPaths('/lowerPlumbic')">低压铅酸</div>
          <div class="con mb20" @click="toPaths('/plumbicRecovery')">铅酸回收</div>
          <div class="con mb20" @click="toPaths('/lowLithium')">低压锂电</div>
          <div class="con mb20" @click="toPaths('/lithiumRecovery')">锂电再生</div>
          <!-- <div class="con mb20" @click="toPaths('/energyBusiness')">储能业务</div> -->
          <div class="con mb20" @click="toPaths('/intelligentLogistics')">智慧物流</div>
          <div class="con" @click="toPaths('/userAnswer')">客户答疑</div>
        </div>
        <div>
          <div class="title">智能制造</div>
          <!-- <div class="con mb20" @click="toPaths('/innovative')">创新研发</div> -->
          <div class="con mb20" @click="toPaths('/intelligent')">智能制造 </div>
          <div class="con"  @click="toPaths('/certification')">体系认证</div>
        </div>
        <div>
          <div class="title">全球市场</div>
          <div class="con mb20" @click="toPaths('/domestic')">国内市场</div>
          <div class="con mb20" @click="toPaths('/international')">国际市场 </div>
          <!-- <div class="con mb20" @click="toPaths('/supporting')">配套市场</div> -->
          <!-- <div class="con" @click="toPaths('/eCommerce')">电商业务</div> -->
        </div>
        <div>
          <div class="title">新闻中心</div>
          <div class="con mb20" @click="toPaths('/businessMovement')">国内动态</div>
          <div class="con mb20" @click="toPaths('/nationalMovement')">国际动态</div>
          <!-- <div class="con mb20" @click="toPaths('/notice')">通知公告 </div>
          <div class="con mb20"  @click="toPaths('/special')">专题专栏</div> -->
          <div class="con"  @click="toPaths('/videoCenter')">视频专区</div>
        </div>
        <div>
          <div class="title">可持续发展</div>
          <div class="con mb20"  @click="toPaths('/esg')">ESG治理 </div>
          <div class="con mb20" @click="toPaths('/greenDevelopment')">绿色发展</div>
          <div class="con mb20" @click="toPaths('/environmental')">环境公开</div>
          <div class="con" @click="toPaths('/informationSecurity')">信息安全</div>
        </div>
        <div>
          <div class="title">投资者关系</div>
          <div class="con mb20"  @click="open(3)">股票信息</div>
          <div class="con mb20" @click="toPaths('/investorProtection')">投资者保护 </div>
          <div class="con mb20"  @click="open(4)">投资者问答</div>
          <div class="con mb20"  @click="toPaths('/periodicReport')">定期报告</div>
          <div class="con" @click="open(5)">信息披露</div>
        </div>
        <div>
          <div class="title">关于骆驼</div>
          <div class="con mb20" @click="toPaths('/groupProfile')">集团简介</div> 
          <div class="con mb20" @click="toPaths('/culture')">企业文化</div>
          <div class="con mb20" @click="toPaths('/strategy')">发展战略</div>
          <div class="con mb20" @click="toPaths('/history')">发展历程</div>
          <div class="con mb20" @click="toPaths('/positioning')">品牌定位</div>
          <div class="con mb20" @click="toPaths('/honorary')">荣誉资质</div>
        
        </div>
        <div>
          <div class="title">加入我们</div>
          <div class="con mb20" @click="toPaths('/talent')">人才招聘</div>
          <!-- <div class="con mb20" @click="toPaths('/purchase')">申请采购商</div>
          <div class="con" @click="toPaths('/agency')">申请经销商</div> -->
        </div>
        <div>
          <div class="title">联系我们</div>
          <div class="con mb20" @click="toPaths('/connect')">联系我们</div>
          <div class="con mb20" @click="toPaths('/purchase')">供应链平台</div>
          <div class="con" @click="toPaths('/agency')">经销商合作</div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="d2">
        <img src="./img/1.jpg">
        <span>骆驼股份公众号</span>
      </div>
      <div class="d2">
        <img src="./img/2.jpg">
        <span>骆驼养车公众号</span>
      </div>
    </div>
    <div class="copyright">
      <div class="copyright-t">
        <span> COPYRIGHT © 2016 骆驼集团股份-B·太阳城3有限公司 ALL RIGHTS RESERVED</span>
      </div>
      <div class="copyright-c">
        <span> 备案号：鄂ICP备15000576号-3</span>
      </div>
      <div>
        <img class="gongan" src="./img/gongan.png">
        <span>鄂公网安备 42060602000269号</span>
      </div>
      <div class="safe"><span>400-189-9507（客服电话）</span> </div>
    </div>
    
  </div>
</template>
<script>
export default {
  created(){},
  methods:{
    toPaths(url,query){
      this.$router.push({path:url,query})
    },
    open(type){
      let url=''
      if(type==1){
        url='https://app.autocamel.com/tender/view/login.jsp'
      }else if(type==2){
        url='https://jt.autocamel.com/purchase/login.jsp'
      }else if(type==3){
        url='http://quote.eastmoney.com/sh601311.html'
      }else if(type==4){
        url='http://sns.sseinfo.com/searchFullText.do?keyword=601311'
      }else if(type==5){
        url='http://www.sse.com.cn/assortment/stock/list/info/company/index.shtml?COMPANY_CODE=601311'
      }else if(type==6){
        url='https://portal.chinacamel.com/'
      }else if(type==7){
        url='https://mail.chinacamel.com/'
      }
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 767px){
  .footer{
    padding:0.66rem 0.3rem 0.4rem ;
    // padding-top:  ;
    // padding-left: 0.7rem ;
    // padding-bottom: 0.4rem;
    background: #0068B7;
    .footer-main{
      display: none;
    }
    .copyright{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center ;
      font-size: 0.18rem;
      font-family: FZLanTingHeiS-R-GB;
      font-weight: 400;
      color: #FFFFFF;
      span{
        font-size: 0.18rem;
      }
      .safe{
        margin-top: 0.16rem;
      }
      .gongan{
        // width: 12px;
        // height:100%;
        padding-right: 0.1rem;
        vertical-align: bottom;
      }
      &-c{
        margin: 0.16rem 0 0.20rem 0;
      }
      &-t{
        word-wrap: break-word;
        text-align: center;
        
      }
      
    }
   .mobile{
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.54rem;
    .d2{
      display: flex;
      flex-direction: column;
      img{
        width: 1.76rem;
        height: 1.76rem;
        margin-bottom: 0.17rem;
      }
      span{
        font-size: 0.24rem;
        font-family: FZLanTingHeiS-R-GB;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
   }
  }
}
@media screen and (min-width: 767px){
  .footer{
    padding-top: 0.90rem;
    padding-bottom: 0.33rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #0068B7;
    &-main{
      display: flex;
      &-l{
        position: relative;
        &::after{
            position: absolute;
            content: "";
            top: 0;
            right: -0.03rem;
            height: 0.83rem;
            width: 0.03rem;
            background: #E4E4E4;
          }
        .logo{
          width: 2.42rem;
          height: 0.84rem;
          position: relative;
          box-sizing: border-box;
        }
        border-right: 1px solid #E4E4E4;
        padding-right: 0.92rem;
        .footer-f1{
          margin-top: 0.45rem;
          display: flex;
          justify-content: space-between;
          &-r{
            .d3{
              opacity: 0;
            }
          }
          .d1{
            display: flex;
            margin-bottom: 0.14rem;
            img{
              width: 0.42rem;
              height: 0.34rem;
              margin-right: 0.11rem;
              vertical-align: top;
            }
            span{
              font-size: 0.16rem;
              font-family: FZLanTingHeiS-R-GB;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 0.34rem;
            }
          }
          .d2{
            display: flex;
            flex-direction: column;
            img{
              width: 1rem;
              height: 1rem;
              margin-bottom: 0.09rem;
            }
            span{
              font-size: 0.14rem;
              font-family: FZLanTingHeiS-R-GB;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .d3{
            display: flex;
            align-items: center;
            margin-bottom: 0.38rem;
            img{
              width: 0.37rem;
              height: 0.28rem;
            }
            span{
              font-size: 0.16rem;
              margin-left: 0.14rem;
              font-family: FZLanTingHeiS-R-GB;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .d4{
            display: flex;
            align-items: center;
            margin-bottom: 0.38rem;
            img{
              width: 0.30rem;
              height: 0.28rem;
            }
            span{
              font-size: 0.16rem;
              margin-left: 0.14rem;
              font-family: FZLanTingHeiS-R-GB;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .d5{
            display: flex;
            align-items: center;
            margin-bottom: 0.14rem;
            img{
              width: 0.31rem;
              height: 0.34rem;
            }
            span{
              font-size: 0.16rem;
              margin-left: 0.14rem;
              font-family: FZLanTingHeiS-R-GB;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .d6{
            display: flex;
            align-items: center;
            margin-bottom: 0.14rem;
            img{
              width: 0.39rem;
              height: 0.39rem;
            }
            span{
              font-size: 0.16rem;
              margin-left: 0.14rem;
              font-family: FZLanTingHeiS-R-GB;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .d7{
            display: flex;
            align-items: center;
            margin-bottom: 0.20rem;
            margin-top: 0.09rem;
            img{
              width: 0.32rem;
              height: 0.24rem;
            }
            span{
              font-size: 0.16rem;
              margin-left: 0.14rem;
              font-family: FZLanTingHeiS-R-GB;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }
      }
      &-r{
        padding-left: 0.98rem;
        flex: 1;
        display: flex;
        justify-content: space-between;
        .title{
          font-size: 0.20rem;
          font-family: FZLanTingHeiS-B-GB;
          font-weight: 400;
          color: #FFFFFF;
          margin-bottom: 0.4rem;
          cursor: pointer;
        }
        .con{
          font-size: 0.16rem;
          font-family: FZLanTingHeiS-R-GB;
          font-weight: 400;
        
          cursor: pointer;
          color: #93b9d6;
        }
        .con:hover{
          // font-weight: bold;
          color: #F0F0F0;
        }
        
      }
    }
    .mobile{
      display: none;
    }
    .copyright{
      margin-top: 0.9rem;
      
      display: flex;
      justify-content: center;
      align-items: baseline;
      span{
        font-size: 12px;
        font-family: FZLanTingHeiS-R-GB;
        font-weight: 400;
        color: #E4E4E4;
      }
      &-c{
        margin: 0 0.4rem;
        color: #E4E4E4;
      }
      .gongan{
        // width: 0.18rem;
        height:auto;
        // object-fit: contain;
        padding-right: 0.1rem;
        vertical-align: bottom;
      }
      .safe{
        margin-left: 0.4rem;
        color: #E4E4E4;
      }
    }
    
  }
}
</style>